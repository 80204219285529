import styled from "@emotion/styled";
import type { PropsWithChildren, ReactElement, SyntheticEvent } from "react";
import React, { useCallback, useEffect } from "react";
import type {
  DefaultValues,
  FieldErrors,
  FieldValues,
  UseFormReturn
} from "react-hook-form";
import type { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import MultiStepFormCubit from "src/state/MultiStepFormCubit/MultiStepFormCubit";
import { useBloc } from "src/state/state";
import Form from "src/ui/components/Form/Form";
import FormConnect from "src/ui/components/FormConnect/FormConnect";

interface Props<T extends FieldValues> {
  name: string;
  alwaysShow?: boolean;
  onError?: (errors: FieldErrors<T>) => void;
  grow?: boolean;
  buttonLabel?: string;
  reset?: boolean;
  loadingKey?: LoadingKey;
  useDefaultResolver?: boolean;
  hideLoading?: boolean;
  hideButton?: boolean;
  noPadding?: boolean;
  centerButton?: boolean;
  defaultValues?: DefaultValues<T>;
  limitWidth?: boolean;
  shrink?: boolean;
  belowButton?: ReactElement;
  justifyCenter?: boolean;
  alignCenter?: boolean;
  onComplete?: (
    data: T,
    events: UseFormReturn<T, AnyObject>
  ) => Promise<boolean>;
  onChange?: (
    event: SyntheticEvent,
    data: T,
    events: UseFormReturn<T, AnyObject>
  ) => void;
}

const StyledForm = styled.div<{
  justifyCenter?: boolean;
  alignCenter?: boolean;
}>`
  form {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: ${({ justifyCenter }): string =>
      justifyCenter ? "center" : "initial"};
    align-items: ${({ alignCenter }): string =>
      alignCenter ? "center" : "initial"};
  }
`;

function FormStep<T extends FieldValues>({
  grow = true,
  justifyCenter = true,
  alignCenter = false,
  ...props
}: PropsWithChildren<Props<T>>): ReactElement | null {
  const [{ activeStep }, { registerStep, nextStep }] =
    useBloc(MultiStepFormCubit);

  const visible = props.alwaysShow === true || activeStep === props.name;

  useEffect(() => {
    registerStep(props.name);
  }, []);

  const handleSubmit = useCallback(
    (data: T, events: UseFormReturn<T, AnyObject>): void => {
      void props.onComplete?.(data, events).then((canContinue) => {
        if (canContinue) {
          nextStep();
        }
      });
    },
    []
  );

  return visible ? (
    <StyledForm
      justifyCenter={justifyCenter}
      alignCenter={alignCenter}
      className="form-step"
    >
      <Form<T>
        onSubmit={handleSubmit}
        onError={props.onError}
        onChange={props.onChange}
        defaultValues={props.defaultValues}
        grow={grow}
        hideLoading={props.hideLoading}
        loadingKey={props.loadingKey}
      >
        <FormConnect>{props.children}</FormConnect>
      </Form>
    </StyledForm>
  ) : null;
}

export default FormStep;
