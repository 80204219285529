import { Cubit } from "blac";

interface MultiStepFormState {
  steps: string[];
  activeStep: string;
  useRouting: boolean;
}

export default class MultiStepFormCubit extends Cubit<MultiStepFormState> {
  debufRegister: ReturnType<typeof setTimeout> = setTimeout(() => {});
  private _steps: string[] = [];
  returnRedirectPath?: string;
  setInitialStep: boolean;

  constructor(
    options: {
      useRouting?: boolean;
      returnRedirectPath?: string;
      setInitialStep?: boolean;
    } = {}
  ) {
    super({
      steps: [],
      activeStep: "",
      useRouting: Boolean(options.useRouting)
    });

    // if (typeof options.setInitialStep === "boolean") {
    //   this.setInitialStep = Boolean(options.setInitialStep);
    // } else {
    this.setInitialStep = true;
    // }

    if (options.useRouting) {
      this.returnRedirectPath = options.returnRedirectPath;
      this.addPathChangeListener();
      this.addRemoveListener(this.removePathChangeListener);
    }
  }

  private readonly autoSetActiveStep = (): void => {
    if (this.setInitialStep) {
      this.setActiveStep(0, true);
    } else if (this.state.activeStep && this.state.steps.length !== 0) {
      this.setActiveStepByName(this.state.activeStep, {
        force: true,
        replace: true
      });
    }
  };

  private readonly handlePathChange = (): void => {
    const locationHash = window.location.hash;

    // prevent empty screen when pressing back button after questionnaire initial load
    if (!locationHash) {
      this.autoSetActiveStep();
      return;
    }

    const hash = locationHash.replace(/^#/, "");

    this.emit({ ...this.state, activeStep: hash });
  };

  private readonly addPathChangeListener = (): void => {
    window.addEventListener("hashchange", this.handlePathChange);
  };

  private readonly removePathChangeListener = (): void => {
    window.removeEventListener("hashchange", this.handlePathChange);
  };

  public readonly registerStep = (name: string): void => {
    if (this._steps.includes(name)) return;

    this._steps.push(name);
    clearTimeout(this.debufRegister);
    this.debufRegister = setTimeout((): void => {
      this.emit({ ...this.state, steps: this._steps });
      this.autoSetActiveStep();
    }, 10);
  };

  public readonly setSteps = (steps: string[]): void => {
    this._steps = steps;
    this.emit({ ...this.state, steps });
  };

  private readonly setActiveStep = (index: number, replace = false): void => {
    if (this.state.useRouting) {
      if (replace) {
        history.replaceState(undefined, "", `#${this.state.steps[index]}`);
        this.handlePathChange();
      } else {
        window.location.hash = this.state.steps[index];
      }
    }
    this.emit({ ...this.state, activeStep: this.state.steps[index] });
  };

  public readonly setActiveStepByName = (
    name: string,
    options: { force?: boolean; replace?: boolean } = {}
  ): void => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (options.force || this.state.activeStep !== name) {
      if (this.state.useRouting) {
        if (options.replace) {
          history.replaceState(undefined, "", `#${name}`);
          this.handlePathChange();
        } else {
          window.location.hash = name;
        }
      }
      this.emit({ ...this.state, activeStep: name });
    }
  };

  public readonly nextStep = (): void => {
    const activeIndex = this.state.steps.indexOf(this.state.activeStep);
    const nextIndex = activeIndex + 1;
    if (this.state.steps[nextIndex]) {
      this.setActiveStep(nextIndex);
    }
  };

  public readonly prevStep = (): void => {
    const activeIndex = this.state.steps.indexOf(this.state.activeStep);
    const nextIndex = activeIndex - 1;
    if (this.state.steps[nextIndex]) this.setActiveStep(nextIndex);
  };
}
