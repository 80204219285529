import type { ButtonColor, NineForm } from "@9amhealth/wcl";
import { NineInput } from "@9amhealth/wcl";
import { keyframes } from "@emotion/css";
import { NineFunnelStep } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import type { CSSProperties, FC } from "react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import type { DeepPartial, FieldValues, UseFormReturn } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import VimeoService from "src/api/VimeoService";
import { FUNNEL_QUESTIONNAIRE_ELIGIBILITY } from "src/constants/misc";
import getErrorForField from "src/lib/getErrorForField";
import { NineamComponents } from "src/lib/HtmlParser";
import translate from "src/lib/translate";
import MultiStepFormCubit from "src/state/MultiStepFormCubit/MultiStepFormCubit";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import type {
  QuestionnaireEndScreen,
  QuestionnaireField
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import QuestionnaireStepCubit, {
  QuestionnaireFieldAttachmentType,
  QuestionnaireType
} from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { BlocProvider, useBloc } from "src/state/state";
import VideoPlayerCubit from "src/state/VideoPlayerCubit/VideoPlayerCubit";
import DynamicInput from "src/ui/components/DynamicInput/DynamicInput";
import Link, { rewriteAppLinkUrls } from "src/ui/components/Link/Link";
import { LoadingIndicator } from "src/ui/components/Loader/Loader";
import FormStep from "src/ui/components/MultiStepForm/FormStep";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import ScrollToMe from "src/ui/components/ScrollToMe/ScrollToMe";
import Translate from "src/ui/components/Translate/Translate";
import MediaPlayer from "../MediaPlayer/MediaPlayer";

type AttachmentPosition =
  | "after-description"
  | "after-title"
  | "before-title"
  | undefined;

const LinkWrap = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 1rem;

  a {
    color: var(--color-error);
    font-weight: bold;
    font-size: 1em;
    text-decoration: none;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  transition: padding-bottom 0.5s cubic-bezier(0.38, 0.7, 0.125, 1);

  &:has(:focus-within) {
    padding-bottom: calc(var(--stored-keyboard-height, 0px));
  }
`;

const InputWrapper = styled.div`
  order: 50;
`;

const Other = styled.div`
  order: 99;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 1.2em;
  order: 80;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  &[aria-hidden="true"] {
    animation: none;
    animation-fill-mode: none;
  }
`;

const StepTitle = styled.div`
  order: 10;

  .description-first & {
    order: 20;
    margin-top: 1em;
  }

  .small-title & {
    h4 {
      font-family: var(--font-family);
      font-size: var(--font-size, 1.25rem);
      font-style: var(--font-style, normal);
      font-weight: var(--font-weight, 500);
      line-height: var(--line-height, 140%); /* 1.75rem */
      letter-spacing: var(--spacing, -0.025rem);
    }
  }
`;

const Description = styled.div`
  order: 20;

  .description-first & {
    order: 10;
  }

  &.align-left {
    text-align: left;
  }

  em {
    font-style: normal;
    font-weight: bold;
    margin-top: 1rem;
    display: block;

    & + br {
      display: none;
    }
  }

  p {
    &:first-of-type {
      margin-top: 0.3em;
    }
  }

  ul {
    margin-bottom: 1em;

    li {
      margin: 0.2em 0;
    }

    &:last-child {
      margin-bottom: 1em;
    }
  }
`;

const Attachment = styled.div`
  order: 30;
  margin: 1em 0 0;

  .attachment-top & {
    order: 5;
    margin: 0 0 1em;
  }

  .attachment-middle & {
    order: 15;
    margin: 1em 0;
  }

  img {
    max-width: min(100%, 600px);
    border-radius: 1em;
    display: block;
    margin: 0 auto;
  }

  &.aspect {
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 495/287;
      object-fit: cover;
      background-color: var(--color-cream-dark);
    }
  }
`;

const InputDescription = styled.small`
  display: block;
  color: var(--color-charcoal-80);
  text-align: left;
  margin: 0.75rem 0;
`;

export const QuestionnaireStepSingleField: FC<{
  rawField: QuestionnaireField;
  isFinishStep?: boolean;
  isFirstStep?: boolean;
  showLoginLink?: boolean;
  onComplete?: () => unknown;
  validateOnInit?: boolean;
}> = ({
  rawField,
  isFirstStep,
  showLoginLink,
  onComplete,
  validateOnInit = false
}) => {
  const {
    formState: { errors },
    clearErrors,
    setError
  } = useFormContext();
  const formRef = useRef<NineForm>();
  const [formStatus, setFormStatus] = useState<NineForm["status"]>("pristine");

  const url = window.location.href;
  const urlIncludesIframe = url.includes("iframe");
  const isInsideIframe = urlIncludesIframe;

  const { error } = getErrorForField({
    name: rawField.id,
    errors,
    labelText: ""
  });

  const [{ customFormData }, { insertPlaceholders, setFieldStatus }] =
    useBloc(QuestionnaireCubit);
  const field = insertPlaceholders(rawField);
  const typeVideo = !!field.properties?.description?.includes(
    NineamComponents.video
  );

  let src = field.attachment?.href;

  if (typeVideo && !src) {
    const srcMatch = field.properties?.description?.match(/src="([^"]+)"/);

    if (srcMatch && srcMatch[1]) {
      src = srcMatch[1];
    }
  }

  const videoId = src && VimeoService.extractIdFromUrl(src);
  const [, { isVideoWatched }] = useBloc(VideoPlayerCubit);
  const videoWatched = !!(videoId && isVideoWatched(videoId));

  const hasStepValue = customFormData[field.id] ?? false;

  const valueIsEmpty =
    typeof hasStepValue === "undefined" ||
    hasStepValue === "" ||
    hasStepValue === false ||
    (typeof hasStepValue === "object" && hasStepValue.length === 0);

  const showContinueButton = useMemo<boolean>(
    () => {
      // show if the field already has a value
      if (!valueIsEmpty) return true;

      // show button if the option is set, no matter what else follows
      if (field.properties?.show_button === true) return true;

      // hide button if the option is set, no matter what else follows
      if (field.properties?.hide_button === true) return false;

      // disable button if the the field will continue automatically
      if (field.properties?.auto_continue) return false;

      // disable button for yes/no questions, it continues when the user clicks on the answer
      if (field.type === QuestionnaireType.YES_NO) return false;

      // disable button for "multiple_choice" questions where only one options is possible, it continues when the user clicks on the answer
      if (
        field.type === QuestionnaireType.MULTIPLE_CHOICE &&
        !field.properties?.allow_multiple_selection
      )
        return false;

      // for opinion scale, hide if the field is required and there is no value
      if (
        field.type === QuestionnaireType.OPINION_SCALE &&
        field.validations?.required
      )
        return false;

      // disable button if ineligible option is set
      if (field.properties?.ineligible) return false;

      if (
        field.properties?.description?.includes(NineamComponents.video) &&
        !videoWatched
      ) {
        return false;
      }

      return true;
    },
    // `field` is not added as a dependency so that this is not updated once the field value changes
    [hasStepValue, Boolean(videoWatched)]
  );

  const isRequired =
    Boolean(field.validations?.required) || !showContinueButton;

  const buttonLink =
    field.properties?.button_link ?? field.properties?.redirect_url;
  const buttonText = field.properties?.button_text;
  const buttonColor = field.properties?.button_color;

  const buttonDisabledForMedicationField = useMemo((): boolean => {
    if (field.properties?.medication_field) {
      const values = Object.values(customFormData[field.id] ?? {}).filter(
        Boolean
      );
      return values.reduce((x, y) => Number(x) + Number(y), 0) <= 0;
    }
    return false;
  }, [customFormData, field.id, field.properties?.medication_field]);

  const disableButton =
    Boolean(error) ||
    (isRequired && valueIsEmpty) ||
    buttonDisabledForMedicationField;

  const hasIssue = (isRequired && valueIsEmpty) || formStatus === "error";

  const setAllStatus = (): void => {
    if (!formRef.current) return;

    setFormStatus(formRef.current.status);
    if (formRef.current.status === "error") {
      setError(field.id, {
        message: "error"
      });
    }
  };

  useEffect(() => {
    setFieldStatus(field.id, hasIssue);
  }, [hasIssue, field.id]);

  const renderAttachment = (
    attachment: QuestionnaireField["attachment"]
  ): JSX.Element | null => {
    if (!attachment) return null;

    switch (attachment.type) {
      case QuestionnaireFieldAttachmentType.IMAGE: {
        const description = attachment.properties?.description ?? "";

        // remove anything wrapped in [*]
        const parsedDescription = description.replace(/\[.*?\]/g, "").trim();

        return (
          <>
            <img src={attachment.href} alt={parsedDescription} />
          </>
        );
      }
      case QuestionnaireFieldAttachmentType.VIDEO: {
        const src = field.attachment?.href;
        const id = src && VimeoService.extractIdFromUrl(src);

        if (!id) {
          return null;
        }

        return (
          <MediaPlayer
            videoId={id}
            videoProvider="vimeo"
            title=""
            playIcon="simple"
            inline
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const parsedDesc = field.properties?.parsedDescription;
  const descriptionLength = field.properties?.description?.length ?? 0;
  const textCenter = field.properties?.horizontal_alignment?.description;

  const alignDescriptionLeft = !textCenter && descriptionLength > 180;

  // check if its an empty array
  const parsedDescIsEmpty =
    Array.isArray(parsedDesc) && parsedDesc.length === 0;

  // auto continue logic part
  useEffect(() => {
    let mounted = true;
    if (field.properties?.auto_continue && !showContinueButton) {
      setTimeout(() => {
        if (mounted) {
          onComplete?.();
        }
      }, 2_500);
    }
    return () => {
      mounted = false;
    };
  }, [showContinueButton]);

  const initialized = useRef(false);
  useEffect(() => {
    if (validateOnInit) {
      // validate after short delay to make sure the fields have been registered and the default values have been set
      setTimeout(() => {
        if (!formRef.current) return;
        void formRef.current.handleSubmit().then(() => {
          initialized.current = true;
        });

        setFormStatus(formRef.current.status);

        setAllStatus();
      }, 50);
    } else {
      initialized.current = true;
    }
  }, [formRef]);

  const handleChange = (): void => {
    if (formRef.current && initialized.current) {
      clearErrors(field.id);
      if (validateOnInit) {
        void formRef.current.handleSubmit();
        setAllStatus();
      }
    }
  };

  const attachmentPosition = useMemo<AttachmentPosition>(() => {
    const attm = field.attachment;
    if (!attm) return;

    const description = attm.properties?.description ?? "";

    if (description.includes("[position:after-title]")) return "after-title";
    if (description.includes("[position:after-description]"))
      return "after-description";
    if (description.includes("[position:before-title]")) return "before-title";

    return "after-title";
  }, [field.attachment]);

  const { attachmentRounded, attachmentIcon } = useMemo(() => {
    const attm = field.attachment;
    if (!attm) {
      return { attachmentIcon: false, attachmentRounded: false };
    }

    const description = attm.properties?.description ?? "";

    const isPropertyDefined = (property: string) => {
      return description.includes(property);
    };

    const rounded = isPropertyDefined("[rounded:full]");
    const icon = isPropertyDefined("[icon:true]");

    return { attachmentRounded: rounded, attachmentIcon: icon };
  }, [field.attachment]);

  const smallTitle = field.attachment && attachmentPosition === "before-title";
  const parsedLink = rewriteAppLinkUrls(buttonLink);
  const isExternal = parsedLink?.startsWith("http");

  const attachmentLink = useMemo<string | undefined>(() => {
    const linkRegex = /\[link="(.+)?"\]/gm;
    const [, link] =
      linkRegex.exec(field.attachment?.properties?.description ?? "") ?? [];
    return link;
  }, [field.attachment]);

  const inputDescription = field.properties?.input_description;

  // Set button as full-width if there is video, textarea or full-width img on screen
  const has9amVideoEmbedded = field.properties?.description?.includes(
    NineamComponents.video
  );
  const hasTextarea = field.type === QuestionnaireType.LONG_TEXT;
  const buttonFullWidth =
    (attachmentPosition && !attachmentRounded && !attachmentIcon) ||
    has9amVideoEmbedded ||
    hasTextarea;

  const textAlignLeft =
    (attachmentPosition && !attachmentRounded && !attachmentIcon) ||
    hasTextarea;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <OnEvent
        events={{
          [NineInput.customEvents.change]: handleChange
        }}
      >
        <NineFunnelStep
          data-el-height
          center="true"
          ref={formRef as unknown as never}
          style={
            {
              "--header-height": "0",
              marginTop: `var(--margin-top, -3em)`,
              fontSize: 16
            } as CSSProperties
          }
          key={field.id}
          id={field.id}
          data-step-ref={field.ref}
          className={clsx(`is-${field.type}`, {
            "small-title": smallTitle,
            "is-required": isRequired,
            "is-empty": valueIsEmpty,
            "is-optional": !isRequired,
            "has-issue": hasIssue,
            "description-first": field.properties?.description_first,
            "attachment-top": attachmentPosition === "before-title",
            "attachment-middle": attachmentPosition === "after-title",
            "attachment-bottom": attachmentPosition === "after-description",
            "text-left": textAlignLeft
          })}
        >
          {!isInsideIframe && <ScrollToMe key={field.id} />}
          <Wrap>
            {field.attachment && (
              <Attachment
                className={clsx({
                  aspect: attachmentPosition === "before-title",
                  rounded: attachmentRounded,
                  icon: attachmentIcon
                })}
              >
                {attachmentLink ? (
                  <Link href={attachmentLink} target="_blank">
                    {renderAttachment(field.attachment)}
                  </Link>
                ) : (
                  renderAttachment(field.attachment)
                )}
              </Attachment>
            )}

            <StepTitle>
              <h4>{field.parsedTitle}</h4>
            </StepTitle>

            <Description
              className={clsx("step-description", {
                "align-left": alignDescriptionLeft
              })}
            >
              {parsedDesc && !parsedDescIsEmpty && (
                <>
                  <nine-spacer s="xs"></nine-spacer>
                  <div className="as-body" style={{ marginBottom: 0 }}>
                    {parsedDesc}
                  </div>
                </>
              )}
            </Description>

            <InputWrapper className="step-input">
              <DynamicInput
                field={field}
                onComplete={onComplete}
                onChange={handleChange}
                showContinueButton={showContinueButton}
              />
              {inputDescription && (
                <InputDescription>{inputDescription}</InputDescription>
              )}
            </InputWrapper>

            <ButtonWrapper
              className="step-button"
              aria-hidden={showContinueButton ? "false" : "true"}
            >
              <nine-button
                disabled={disableButton ? "true" : "false"}
                type={buttonLink ? "" : "submit"}
                href={parsedLink}
                target={isExternal ? "_blank" : undefined}
                color={buttonColor as ButtonColor | undefined}
                fullWidth={buttonFullWidth}
              >
                {buttonText}
              </nine-button>
            </ButtonWrapper>
          </Wrap>

          {showLoginLink && isFirstStep && (
            <Other className="step-other">
              <nine-spacer s="sm"></nine-spacer>
              <LinkWrap>
                <Translate msg="login.alternative.question" /> &nbsp;
                <Link to="/app" style={{ color: "var(--color-charcoal)" }}>
                  <Translate msg="login" />
                </Link>
              </LinkWrap>
            </Other>
          )}
        </NineFunnelStep>
      </OnEvent>
    </motion.div>
  );
};

export const QuestionnaireStepFields: FC<{
  isFinishStep?: boolean;
  isFirstStep?: boolean;
  showLoginLink?: boolean;
  onComplete: () => unknown;
  validateOnInit?: boolean;
}> = ({
  isFinishStep = false,
  isFirstStep = false,
  showLoginLink = false,
  onComplete,
  validateOnInit
}) => {
  const [{ fields }] = useBloc(QuestionnaireStepCubit);

  return (
    <AnimatePresence>
      {fields.map((rawField) => (
        <QuestionnaireStepSingleField
          key={rawField.id}
          isFinishStep={isFinishStep}
          isFirstStep={isFirstStep}
          rawField={rawField}
          showLoginLink={showLoginLink}
          onComplete={onComplete}
          validateOnInit={validateOnInit}
        />
      ))}
    </AnimatePresence>
  );
};

export const QuestionnaireStepContent: FC<{
  onSubmit?: () => Promise<boolean>;
  onFinish?: () => Promise<boolean>;
  showThankYouStep?: boolean;
  showLoginLink?: boolean;
  alwaysShow?: boolean;
  validateOnInit?: boolean;
  resetScrollOnEachStep?: boolean;
}> = (props) => {
  const [{ activeStep }] = useBloc(MultiStepFormCubit);
  const [
    ,
    {
      customFormData,
      isFinishStep,
      isFirstStep,
      runLogicForField,
      runValidations,
      onComplete
    }
  ] = useBloc(QuestionnaireCubit);
  const [{ fields }, { handleSubmit, handleChange }] = useBloc(
    QuestionnaireStepCubit
  );

  const fieldsIds = fields.map((field) => field.id);
  // only pass relevant values to form as initialValues, to prevent the form from setting values for other steps
  const stepFieldValues = useMemo(() => {
    const selected = fieldsIds.reduce<DeepPartial<FieldValues>>((acc, id) => {
      acc[id] = customFormData[id];
      return acc;
    }, {});

    // check if any of the fieldValues is an array
    const listItems = Object.values(selected).filter(
      (value) => Array.isArray(value) && value.length > 0
    );

    // for each array, we need to also pass the values of the list items
    listItems.forEach((listItem: string[] | string) => {
      const ids = Array.isArray(listItem) ? listItem : [listItem];

      for (const id of ids) {
        const listItemValues = customFormData[id];
        selected[id] = listItemValues;
      }
    });

    return selected;
  }, [fieldsIds, customFormData]);

  const singleField = fields[0] as QuestionnaireField | undefined;
  const isActiveStep = activeStep === singleField?.ref;

  const isThankYou = singleField?.type === QuestionnaireType.THANK_YOU;
  const stepIsFinishStep = isFinishStep(singleField);
  const stepIsFirstStep = isFirstStep(singleField);

  const hideThankYou = isThankYou && !props.showThankYouStep;

  const ref = useRef<HTMLDivElement>(null);

  const handleCompletedStep = useCallback(
    async (_?: unknown, formMethods?: UseFormReturn): Promise<boolean> => {
      const errors = runValidations(singleField);
      if (singleField && errors.length > 0) {
        formMethods?.setError(singleField.id, {
          message: translate(errors[0])
        });
        return false;
      }

      if (stepIsFinishStep) {
        void props.onFinish?.();
        if (singleField) runLogicForField(singleField);
        return false;
      }

      if (props.onSubmit) {
        return props.onSubmit();
      }
      return handleSubmit(singleField);
    },
    [stepIsFinishStep, singleField, props, handleSubmit]
  );

  useEffect(() => {
    // Trigger message event used in health quiz iframe on website (module in hubspot-theme project)
    setTimeout(() => {
      // Only allow message to be sent to known domains
      const allowedDomains = [
        "https://21926153.hs-sites.com",
        "https://join9am.com"
      ];
      for (const domain of allowedDomains) {
        window.parent.postMessage(
          { height: ref.current?.scrollHeight },
          domain
        );
      }
    }, 0);

    if (isActiveStep) {
      if (props.resetScrollOnEachStep) {
        window.scrollTo({
          top: 0,
          left: window.scrollX,
          behavior: "smooth"
        });
        window.nineConfig?.mainScrollTarget?.scrollTo({
          top: 0,
          left: window.scrollX,
          behavior: "smooth"
        });
        document.body.scrollTop = 0;
      }

      if (isThankYou) {
        if (props.onSubmit) {
          void props.onSubmit();
        } else {
          void handleSubmit(singleField);
        }
      }

      if (stepIsFinishStep) {
        void onComplete();
      }
    }
  }, [isActiveStep, stepIsFinishStep]);

  useEffect(() => {
    const to = setTimeout(() => {
      if (isThankYou && hideThankYou && isActiveStep) {
        void onComplete();
      }
    }, 10000);

    return () => clearTimeout(to);
  }, [isThankYou, hideThankYou, onComplete, isActiveStep]);

  return hideThankYou && isActiveStep ? (
    <div style={{ position: "relative" }}>
      <LoadingIndicator />
    </div>
  ) : (
    <FormStep
      hideButton
      name={singleField?.ref ?? "incognito"}
      onChange={handleChange}
      onComplete={handleCompletedStep}
      defaultValues={stepFieldValues}
      alwaysShow={props.alwaysShow}
    >
      <div
        ref={ref}
        style={{ minWidth: props.alwaysShow ? "var(--min-step-width)" : 0 }}
      >
        <QuestionnaireStepFields
          isFinishStep={stepIsFinishStep}
          isFirstStep={stepIsFirstStep}
          showLoginLink={props.showLoginLink}
          onComplete={handleCompletedStep}
          validateOnInit={props.validateOnInit}
        />
      </div>
    </FormStep>
  );
};

const QuestionnaireStep: FC<{
  fields: QuestionnaireEndScreen[] | QuestionnaireField[];
  onSubmit?: () => Promise<boolean>;
  onFinish?: () => Promise<boolean>;
  showThankYouStep?: boolean;
  alwaysShow?: boolean;
  validateOnInit?: boolean;
  resetScrollOnEachStep?: boolean;
}> = (props) => {
  // const mainField = props.fields[0] as QuestionnaireField | undefined;
  const [{ formId }, questionnaire] = useBloc(QuestionnaireCubit, {
    subscribe: false
  });
  const isAbbreviatedQuestionnaire =
    formId === FUNNEL_QUESTIONNAIRE_ELIGIBILITY;

  return (
    <BlocProvider
      bloc={
        new QuestionnaireStepCubit({
          fields: props.fields as QuestionnaireField[],
          questionnaireCubit: questionnaire
        })
      }
    >
      <QuestionnaireStepContent
        resetScrollOnEachStep={props.resetScrollOnEachStep}
        onSubmit={props.onSubmit}
        onFinish={props.onFinish}
        showThankYouStep={props.showThankYouStep}
        showLoginLink={isAbbreviatedQuestionnaire}
        alwaysShow={props.alwaysShow}
        validateOnInit={props.validateOnInit}
      />
    </BlocProvider>
  );
};

export default QuestionnaireStep;
